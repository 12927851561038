import { authHeader } from './authHeader.js';
import {userService} from './userService.js'

var config = require('./config.js');

export const billService = {
    getBill,
    updateBill,
    getBillList,
    getAllBills,
    startSync,
    removeFromQueue,
};

function getBillList(offset, count) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    if (!offset) {
        offset = 0;
    }
    if (!count) {
        count = 200;
    }
    return fetch(config.app.url + `/api/qbdbills/` + offset + '/batchcount/' + count, requestOptions).then(handleResponse);
}

// Limit 200
function getAllBills(offset, count) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    if (!offset) {
        offset = 0;
    }
    if (!count) {
        count = 200;
    }
    return fetch(config.app.url + `/api/qbdbills/`, requestOptions).then(handleResponse);
}

function getBill(invid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/qbdbill/' + invid , requestOptions).then(handleResponse);
}


function updateBill(resource) {
    //console.log(resource);
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(resource)     
    };
    return fetch(config.app.url + '/api/qbdbill/' + resource.id , requestOptions).then(handleResponse);
}

function startSync(resource) {
    //console.log(resource);
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(resource)     
    };
    return fetch(config.app.url + '/api/qbdstarttsync/bill' , requestOptions).then(handleResponse);
}

function removeFromQueue(resource) {
    //console.log(resource);
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(resource)     
    };
    return fetch(config.app.url + '/api/qbdremovesync/bill' , requestOptions).then(handleResponse);
}

function handleResponse(response) {
    if (response.status === 401) {
        return 401;
    }
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log(data.data);
        return data.data;
    });
}

