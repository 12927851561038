import React, { forwardRef } from "react";
import { billService } from '../../service/billService';
import util from '../../service/utility';
import Link from '@material-ui/core/Link';

import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import SendIcon from "@material-ui/icons/Send";
import IconButton from "@material-ui/core/IconButton";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

class BillList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        clientid: undefined,
        invoices: [],
        
        columns: [
        {
          field: 'id',
          title: 'Id',          
          hidden: true,
        },
        {
          field: 'bill_number',
          title: 'Bill #',          
        },
        {
          field: 'client_name',
          title: 'Vendor',
        },
        {
          field: 'bill_date',
          title: 'Bill Date',
          render: rowData => {
            let txt = util.utcToLocal(rowData.invoice_date);
            return (
              <span> {txt}</span>
            );
          } 
        },
        {
          field: 'last_update_date',
          title: 'Last Update Date',  
          render: rowData => {
            let txt = util.utcToLocalDateTime(rowData.last_update_date);
            return (
              <span> {txt}</span>
            );
          }          
        },
        {
          field: 'billtotal',
          title: 'Bill Total',
        }, 
        {
          field: 'status',
          title: 'Status',   
          render: rowData => {
            let txt = util.getInvoiceOrQuoteStatus(rowData.status);
            return (
              <span> {txt}</span>
            );
          }       
        },         
        {
          field: 'last_invoice_sync_date',
          title: 'Last Sync Date',
          render: rowData => {
            let txt = 'not synced';
            if (rowData.last_invoice_sync_date) {
              txt = util.utcToLocalDateTime(rowData.last_invoice_sync_date);
            }
            return (
              <span> {txt}</span>
            );
          } 
        },         
        {
          title: "Sync",
          field: "internal_action",
          editable: false,
          render: (rowData) =>{
              if (rowData.qbd_sync_status && rowData.qbd_sync_status === 1) {
                return (
                  <>
                  <span>in queue</span>&nbsp;
                  <Link href="#" onClick={() => this.handleUnsync(rowData)}>
                    dequeue
                  </Link>                    
                  </>
                );
              } else if (rowData.qbd_sync_status && rowData.qbd_sync_status === 2) {
                return (
                  <span>synched</span>
                );
              } else {
                  return (
                  <IconButton
                      color="primary"
                      onClick={() => this.handleSync(rowData)}
                  >
                      <SendIcon />
                  </IconButton>
                )
              }
            }
        },               
      ],
    };
  }

  /*
  For invoice and bill add a column called: qbd_sync_status as integer
  1 => In queue
  2 => Synched
  3 => Failed

  alter table datamoto_113845.invoice add column qbd_sync_status integer null default null;
  alter table datamoto_113845.bill add column qbd_sync_status integer null default null;

  create a table for qdb-states:
    create table datamoto_113845.qbdstates (
      id serial NOT NULL, 
      companyid bigint not null,
      type varchar not null, 
      docid bigint  not null,
      docnumber varchar not null, 
      custvendid bigint not null, 
      custvendname varchar not null,
      "createdAt" timestamp with time zone NULL DEFAULT NULL,
      "updatedAt" timestamp with time zone NULL DEFAULT NULL,
      currentstate varchar null default null, 
      statedefinition varchar not null, 
      status integer not null, 
      syncmessage varchar null default null,
      PRIMARY KEY (id)
    );
    

    status:
    0 => sync completed
    -1 => sync failed
    2 => ready for next step in state engine (current operation successfully completed)
  */

  componentDidMount() {
    const { offset } = this.props.match.params;
    const { count } = this.props.match.params;
    this.getBills(offset, count);
    // invoiceService.getInvoiceList(offset, count).then(invs => {
    //   if (invs && invs === 401) {
    //     var { from } = this.props.location.state || { from: { pathname: "/login" } };  
    //     this.props.history.push(from);
    //   } else {        
    //     console.log(invs);
    //     this.setState({ invoices: invs })
    //   }
    // });    
  }

  getBills =(offset, count) => {
  billService.getBillList(offset, count).then(invs => {
    //invoiceService.getAllInvoices().then(invs => {
      if (invs && invs === 401) {
        var { from } = this.props.location.state || { from: { pathname: "/login" } };  
        this.props.history.push(from);
      } else {        
        console.log(invs);
        this.setState({ invoices: invs })
      }
    });
  }

  handleAddNew = () => {
    this.props.history.push('/assetedit/' + this.state.clientid, '', this.state) ;               
  }

  handleSync = (rowData) => {
    console.log(rowData)    
    billService.startSync(rowData).then(invs => {
        if (invs && invs === 401) {
          var { from } = this.props.location.state || { from: { pathname: "/login" } };  
          this.props.history.push(from);
        } else {        
          //console.log(invs);
          this.getBills(0, 40);
        }
      });
  }

  handleUnsync = (rowData) => {
    console.log(rowData)    
    billService.removeFromQueue(rowData).then(invs => {
        if (invs && invs === 401) {
          var { from } = this.props.location.state || { from: { pathname: "/login" } };  
          this.props.history.push(from);
        } else {        
          //console.log(invs);
          this.getBills(0, 40);
        }
      });
  }
  
  render() {
    return (   
      <>
      <div className="content-screen">
      <div style={{ width: '73rem' }}>            
        <MaterialTable
          icons={tableIcons}
          title='Datamoto Bills - Quickbooks Desktop Sync'
          columns={this.state.columns}
          data={this.state.invoices}
          //title="Demo Title"
          options={{
            search: true,
            searchFieldAlignment: 'left',
            sorting: true,
            exportButton: true,
            //selection: true,
            pageSize: 20,
            headerStyle: {
              backgroundColor: '#edeff1',
              color: '#000',
              borderColor: '#9da6af',
            }                
          }}
          // onRowClick={((evt, selectedRow) => {
          //   this.props.history.push('/assetdetail/' + this.state.clientid + '/asset/' + selectedRow.id);             
          // })}
        />
      </div>
      <div>&nbsp;</div>
    </div> 
    </>
    );
  }
}

export default BillList;