import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Switch } from "react-router-dom";

import Home from "./components/screens/InvoiceList";
import { PrivateRoute } from './PrivateRoute';
import InvoiceList from "./components/screens/InvoiceList";
import BillList from "./components/screens/BillList";
import QbdStateList from "./components/screens/QbdstatesList"

export default function Routes() {
  localStorage.getItem('user')  
  return (
    <Switch>
      <PrivateRoute path="/" exact component={Home} />

      <PrivateRoute path="/invoicelist/:offset/count/:count"  exact component={InvoiceList} />
      <PrivateRoute path="/billlist/:offset/count/:count"  exact component={BillList} />
      <PrivateRoute path="/qbdslist"  exact component={QbdStateList} />

    </Switch>
  );
}
