import React, { forwardRef } from "react";
import { qbdstateService } from '../../service/qbdstatesService';
import util from '../../service/utility';

import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

class QbdStateList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        clientid: undefined,
        invoices: [],
        
        columns: [
        {
          field: 'id',
          title: 'Id',                        
        },
        {
          field: 'docnumber',
          title: 'Doc #',          
        },
        {
          field: 'createdAt',
          title: 'Create Date',
          render: rowData => {
            let txt = util.utcToLocalDateTimeSec(rowData.invoice_date);
            return (
              <span> {txt}</span>
            );
          } 
        },
        {
          field: 'updatedAt',
          title: 'Update Date',  
          render: rowData => {
            let txt = util.utcToLocalDateTimeSec(rowData.last_update_date);
            return (
              <span> {txt}</span>
            );
          }          
        },
        {
          field: 'currentstate',
          title: 'Current State',
        }, 
        {
          field: 'statedefinition',
          title: 'State Definition',   
        },
        {
          field: 'status',
          title: 'Status',   
        },         
        {
          field: 'syncmessage',
          title: 'Sync Message',
        },         
      ],
    };
  }

  
  componentDidMount() {
    qbdstateService.getAllQbdStates().then(invs => {
      if (invs && invs === 401) {
        var { from } = this.props.location.state || { from: { pathname: "/login" } };  
        this.props.history.push(from);
      } else {        
        console.log(invs);
        this.setState({ invoices: invs })
      }
    });
  }


  handleAddNew = () => {
    this.props.history.push('/assetedit/' + this.state.clientid, '', this.state) ;               
  }

  render() {
    return (   
      <>
      <div className="content-screen">
      <div style={{ width: '73rem' }}>            
        <MaterialTable
          icons={tableIcons}
          title='Datamoto - Quickbooks Desktop Sync Queue'
          columns={this.state.columns}
          data={this.state.invoices}
          //title="Demo Title"
          options={{
            search: true,
            searchFieldAlignment: 'left',
            sorting: true,
            exportButton: true,
            //selection: true,
            pageSize: 20,
            headerStyle: {
              backgroundColor: '#edeff1',
              color: '#000',
              borderColor: '#9da6af',
            }                
          }}
          // onRowClick={((evt, selectedRow) => {
          //   this.props.history.push('/assetdetail/' + this.state.clientid + '/asset/' + selectedRow.id);             
          // })}
        />
      </div>
      <div>&nbsp;</div>
    </div> 
    </>
    );
  }
}

export default QbdStateList;